<template>
  <div class="container">
    <b-loading :is-full-page="true" v-model="gridLoading"></b-loading>

    <template>
      <div
        v-if="getShowConfirmationModal"
        class="modal no-padding"
        id="modal-warning"
      >
        <div class="modal-content" id="confirmationModal">
          <p class="modal-title" id="confirmationModalTitle">
            Are you sure you want to import?
          </p>
          <p class="modal-text" id="confirmationModalText">
            Importing will overwrite existing plan configuration.
          </p>

          <b-upload @input="importPlan" v-model="importFile">
            <span class="confirmButton">
              <span class="file-label">Yes, import</span>
            </span>
          </b-upload>
          <button class="btn-primary-outline" @click="handleModal(null, false)">
            No, cancel
          </button>
        </div>
      </div>

      <div class="grid-search">
        <div class="columns is-centered-vertically">
          <div class="column">
            <b-field>
              <b-input
                size="is-medium"
                placeholder="Search clients"
                v-model="gridSearchCriteria"
                icon-pack="fas"
                icon="search"
                icon-clickable
                @icon-click="searchPlans"
                @input="searchPlans"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column">
              <b-tabs
                class="is-centered-field"
                  type="is-toggle"
                  v-model="activeTab"
                  @input="handleTab(activeTab)"
              >
                <b-tab-item label="Javelina Benefit Plans"/>
                <b-tab-item label="HealthEdge Benefit Plans"/>
              </b-tabs>
          </div>
          <div class="column">
            <b-field grouped position="is-right">
              <b-button
                v-if="activeTab === 0"
                size="is-medium"
                label="Refresh javelina list"
                class="no-border"
                icon-pack="fas"
                icon-right="sync"
                @click="refreshResults"
              />
              <b-button
                v-else
                size="is-medium"
                label="Refresh HealthEdge list"
                class="no-border"
                icon-pack="fas"
                icon-right="sync"
                @click="refreshHealthEdgeResults"
              />
            </b-field>
          </div>
        </div>
      </div>

      <b-table
        v-if="activeTab === 0"
        ref="gridTable"
        :data="gridData.slice(firstIndex, lastIndex)"
        :bordered="true"
        :sticky-header="true"
        :height="600"
        :opened-detailed="rowError"
        class="grid-outer plans-grid"
        detail-key="clientId#planId"
        detailed
        :show-detail-icon="false"
      >
        <b-table-column
          field="clientName"
          label="Client Name"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-left-border no-right-border left-align taller-cell"
        >
          {{ getFormattedString(props.row.clientName) }}
        </b-table-column>

        <b-table-column
          field="clientId"
          label="Client ID"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
        >
          <span @click="() => logClientCoverage(props.row.clientId)">
            {{ getFormattedString(props.row.clientId) }}
          </span>
        </b-table-column>

        <b-table-column
          field="planName"
          label="Plan ID"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
        >
          {{ getFormattedString(props.row.planId) }}
        </b-table-column>

        <b-table-column
          field="planStartDate"
          label="Plan start date"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
        >
          {{ getFormattedDate(props.row.planStartDate) }}
        </b-table-column>

        <b-table-column
          field="planEndDate"
          label="Plan end date"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
        >
          {{ getFormattedEndDate(props.row.planEndDate) }}
        </b-table-column>

        <b-table-column
          field="status"
          label="Plan Status"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
          width="128"
        >
          <span :class="getPlanStatusClass(props.row.status)">
            {{ props.row.status }}
          </span>

          <!-- <span :class="getPlanStatusClass(props.row.status)" v-if="props.row.completed" >Completed</span>
            <span :class="getPlanStatusClass(props.row.status)" v-else-if="props.row.started">Started</span>
            <span :class="getPlanStatusClass(props.row.status)" v-else>Not Started</span>
        -->
        </b-table-column>

        <b-table-column
          label="Create plan"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border center-align taller-cell icons-position"
          width="150"
        >
          <div class="columns icons-position">
            <b-field class="column column-icons icon-left">
              <span
                v-if="canCreate(props.row.status)"
                class="has-text-info"
                data-hover="Start"
                style="cursor: pointer"
                @click="selectPlan(props.row)"
              >
                <img src="../assets/select_button.svg" alt="Start plan" />
              </span>
              <span
                v-if="canEdit(props.row.status)"
                class="has-text-success"
                data-hover="Edit"
                style="cursor: pointer"
                @click="selectPlan(props.row)"
              >
                <img src="../assets/edit_button.svg" alt="Edit plan" />
              </span>
            </b-field>

            <!-- export plan -->
            <b-field class="column column-icons">
              <span
                v-if="exportLoading !== props.row['clientId#planId']"
                class="has-text-success"
                data-hover="Export"
                style="cursor: pointer"
                @click="
                  () =>
                    canCreate(props.row.status) ? {} : exportPlan(props.row)
                "
              >
                <img
                  :class="canCreate(props.row.status) ? 'disabled-opacity' : ''"
                  src="../assets/export_button.svg"
                  alt="Export plan"
                />
              </span>
              <span v-else>
                <img class="rotate" src="../assets/spinner.svg" alt="Loading" />
              </span>
            </b-field>

            <!-- import plan -->
            <b-field class="column column-icons icon-right">
              <b-upload
                v-if="canCreate(props.row.status) && !importLoading"
                @input="importPlan"
                v-model="importFile"
              >
                <span
                  class="has-text-success"
                  data-hover="Import"
                  style="cursor: pointer"
                  @click="selectRow(props.row)"
                >
                  <img src="../assets/import_button.svg" alt="Import plan" />
                </span>
              </b-upload>
              <div v-else-if="!importLoading">
                <span
                  class="has-text-success"
                  data-hover="Import"
                  style="cursor: pointer"
                  @click="handleModal(props.row)"
                >
                  <img src="../assets/import_button.svg" alt="Import plan" />
                </span>
              </div>
              <div v-else>
                <img class="rotate" src="../assets/spinner.svg" alt="Loading" />
              </div>
            </b-field>
          </div>
        </b-table-column>

        <!-- notification -->
        <b-table-column
          field="notifiactionStatus"
          label="Notification Status"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
          width="128"
        >
          <span
            :class="
              getPlanStatusClass(
                props.row.notificationConfig ? 'Completed' : 'Not Started'
              )
            "
          >
            {{ props.row.notificationConfig ? "Completed" : "Not Started" }}
          </span>
        </b-table-column>

        <b-table-column
          label="Create Notification"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border center-align taller-cell icons-position"
          width="150"
        >
          <span
            v-if="props.row.notificationConfig"
            class="has-text-success"
            data-hover="Edit"
            style="cursor: pointer"
            @click="selectPlan(props.row, true)"
          >
            <img src="../assets/edit_button.svg" alt="Edit notification" />
          </span>
          <span
            v-else
            class="has-text-info"
            style="cursor: pointer"
            data-hover="Start"
            @click="selectPlan(props.row, true)"
          >
            <img src="../assets/select_button.svg" alt="Start notification" />
          </span>
        </b-table-column>

        <!-- Error message -->
        <template #detail>
          <MessageComponent
            :hasCloseMessage="true"
            :titleMessage="error.errorTitle"
            :textMessage="error.errorMessage"
            :messageType="'is-danger'"
            @onClose="rowError = []"
          />
        </template>
      </b-table>
      <b-table
        v-else
        ref="gridTable"
        :data="gridData.slice(firstIndex, lastIndex)"
        :bordered="true"
        :sticky-header="true"
        :height="600"
        :opened-detailed="rowError"
        class="grid-outer plans-grid"
        detail-key="clientId#planId"
        detailed
        :show-detail-icon="false"
      >
        <b-table-column
          field="clientName"
          label="Client Name"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-left-border no-right-border left-align taller-cell"
        >
          {{ getFormattedString(props.row.clientName) }}
        </b-table-column>

        <b-table-column
          field="clientId"
          label="Client ID"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
        >
          <span @click="() => logClientCoverage(props.row.clientId)">
            {{ getFormattedString(props.row.clientId) }}
          </span>
        </b-table-column>

        <b-table-column
          field="planName"
          label="Plan ID"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
        >
          {{ getFormattedString(props.row.planId).slice(4) }}
        </b-table-column>

        <b-table-column
          field="planStartDate"
          label="Plan start date"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
        >
          {{ getFormattedDate(props.row.planStartDate) }}
        </b-table-column>

        <b-table-column
          field="planEndDate"
          label="Plan end date"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
        >
          {{ getFormattedDate(props.row.planEndDate) }}
        </b-table-column>

        <b-table-column
          field="status"
          label="Plan Status"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
          width="128"
        >
          <span :class="getPlanStatusClass(props.row.status)">
            {{ props.row.status }}
          </span>

          <!-- <span :class="getPlanStatusClass(props.row.status)" v-if="props.row.completed" >Completed</span>
            <span :class="getPlanStatusClass(props.row.status)" v-else-if="props.row.started">Started</span>
            <span :class="getPlanStatusClass(props.row.status)" v-else>Not Started</span>
        -->
        </b-table-column>

        <b-table-column
          label="Create plan"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border center-align taller-cell icons-position"
          width="150"
        >
          <div class="columns icons-position">
            <b-field class="column column-icons icon-left">
              <span
                v-if="canCreate(props.row.status)"
                class="has-text-info"
                data-hover="Start"
                style="cursor: pointer"
                @click="selectPlan(props.row)"
              >
                <img src="../assets/select_button.svg" alt="Start plan" />
              </span>
              <span
                v-if="canEdit(props.row.status)"
                class="has-text-success"
                data-hover="Edit"
                style="cursor: pointer"
                @click="selectPlan(props.row)"
              >
                <img src="../assets/edit_button.svg" alt="Edit plan" />
              </span>
            </b-field>

            <!-- export plan -->
            <b-field class="column column-icons">
              <span
                v-if="exportLoading !== props.row['productType#planId#configType']"
                class="has-text-success"
                data-hover="Export"
                style="cursor: pointer"
                @click="
                  () =>
                    canCreate(props.row.status) ? {} : exportPlan(props.row)
                "
              >
                <img
                  :class="canCreate(props.row.status) ? 'disabled-opacity' : ''"
                  src="../assets/export_button.svg"
                  alt="Export plan"
                />
              </span>
              <span v-else>
                <img class="rotate" src="../assets/spinner.svg" alt="Loading" />
              </span>
            </b-field>

            <!-- import plan -->
            <b-field class="column column-icons icon-right">
              <b-upload
                v-if="canCreate(props.row.status) && !importLoading"
                @input="importPlan"
                v-model="importFile"
              >
                <span
                  class="has-text-success"
                  data-hover="Import"
                  style="cursor: pointer"
                  @click="selectRow(props.row)"
                >
                  <img src="../assets/import_button.svg" alt="Import plan" />
                </span>
              </b-upload>
              <div v-else-if="!importLoading">
                <span
                  class="has-text-success"
                  data-hover="Import"
                  style="cursor: pointer"
                  @click="handleModal(props.row)"
                >
                  <img src="../assets/import_button.svg" alt="Import plan" />
                </span>
              </div>
              <div v-else>
                <img class="rotate" src="../assets/spinner.svg" alt="Loading" />
              </div>
            </b-field>
          </div>
        </b-table-column>

        <!-- notification -->
        <b-table-column
          field="notifiactionStatus"
          label="Notification Status"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border left-align taller-cell"
          width="128"
        >
          <span
            :class="
              getPlanStatusClass(
                props.row.notificationConfig ? 'Completed' : 'Not Started'
              )
            "
          >
            {{ props.row.notificationConfig ? "Completed" : "Not Started" }}
          </span>
        </b-table-column>

        <b-table-column
          label="Create Notification"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border center-align taller-cell icons-position"
          width="150"
        >
          <span
            v-if="props.row.notificationConfig"
            class="has-text-success"
            data-hover="Edit"
            style="cursor: pointer"
            @click="selectPlan(props.row, true)"
          >
            <img src="../assets/edit_button.svg" alt="Edit notification" />
          </span>
          <span
            v-else
            class="has-text-info"
            style="cursor: pointer"
            data-hover="Start"
            @click="selectPlan(props.row, true)"
          >
            <img src="../assets/select_button.svg" alt="Start notification" />
          </span>
        </b-table-column>

        <!-- Error message -->
        <template #detail>
          <MessageComponent
            :hasCloseMessage="true"
            :titleMessage="error.errorTitle"
            :textMessage="error.errorMessage"
            :messageType="'is-danger'"
            @onClose="rowError = []"
          />
        </template>
      </b-table>
      <br />
      <br />
      <div v-if="selectedClient">
        <h2>{{ selectedClient.clientName }}</h2>
        <!-- <json-viewer
          theme="my-awesome-json-theme"
          :value="selectedClient"
          :expand-depth="5"
        ></json-viewer> -->
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import store from '@/store/store'
import { mapActions, mapGetters } from 'vuex'
import planApi from '@/api/planApi'
import MessageComponent from './../components/form/MessageComponent.vue'
import moment from 'moment'
// import JsonViewer from 'vue-json-viewer'

const PLAN_NOT_STARTED = 'Not Started'
const PLAN_STARTED = 'Started'
const PLAN_COMPLETED = 'Completed'

export default {
  name: 'plans',
  components: {
    MessageComponent
    // JsonViewer
  },
  mounted () {
    store.commit('plans/setTableSourceType', 'JAVELINA')
    //  this.paginatedGridData = this.gridData.slice(0, this.lastIndex)
    this.fetchDefaultNotificationConfig()
    this['plans/updateIsReview'](false)

    const tableScrollBody = this.$el.getElementsByClassName('table-wrapper')[0]
    tableScrollBody.addEventListener('scroll', this.onBodyScroll)
  },
  beforeDestroy () {
    /* Clean up just to be sure */
    const tableScrollBody = this.$el.getElementsByClassName('table-wrapper')[0]
    tableScrollBody.removeEventListener('scroll', this.onBodyScroll)
  },
  computed: {
    ...mapGetters('plans', ['getShowConfirmationModal', 'getClients'])
  },
  updated () {
    this.$nextTick(() => {
      store.dispatch('plans/planDesign/setPlanDesignData', null)
      store.dispatch('plans/network/setNetworkData', null)
      store.dispatch('plans/coverageDetails/setCoverageDetailsData', null)
      store.dispatch('plans/memberCosts/resetMemberCostPageData', null)
      store.dispatch('plans/setup/setSetupData', null)
      store.dispatch('plans/accumulators/resetAccumulatorsData')
    })
  },
  created () {
    this.getAllPlans()
    this.$store.dispatch('plans/updatePlan', {})
  },
  data () {
    return {
      activeTab: 0,
      selectedClient: null,
      gridData: [],
      paginatedGridData: [],
      firstIndex: 0,
      lastIndex: 15,
      oldScrollValue: 0,
      gridLoading: true,
      gridSearchCriteria: '',
      importFile: null,
      rowData: null,
      rowError: [],
      error: {
        errorTitle: '',
        errorMessage: ''
      },
      exportLoading: false,
      showModal: false,
      importLoading: false
    }
  },
  methods: {
    ...mapActions([
      'fetchDefaultNotificationConfig',
      'plans/updateShowConfirmationModal',
      'plans/updateIsReview'
    ]),
    onBodyScroll: _.debounce(function (event) {
      if (
        event.target.scrollTop + event.target.clientHeight + 500 >=
        event.target.scrollHeight
      ) {
        // this.gridLoading = true
        if (this.gridData.length >= this.lastIndex) {
          // this.firstIndex += 2
          this.lastIndex += 5
        }
        //    this.gridLoading = false
      }
    }, 100),
    canCreate (status) {
      return status === PLAN_NOT_STARTED
    },
    canEdit (status) {
      return status === PLAN_COMPLETED
    },
    selectRow (rowData) {
      this.rowData = rowData
    },
    setMessageText (title, message) {
      this.error.errorTitle = title
      this.error.errorMessage = message
      this.importLoading = false
      const tableSourceType = store.getters['plans/getTableSourceType']
      if (tableSourceType === 'JAVELINA') {
        this.rowError.push(this.rowData['clientId#planId'])
      } else {
        this.rowError.push(this.rowData['clientId#planId'])
      }
    },
    async importPlan (fileInput) {
      const file = Array.isArray(fileInput)
        ? fileInput.length > 0
          ? fileInput[0]
          : null
        : fileInput

      this.importLoading = true
      this['plans/updateShowConfirmationModal'](false)

      if (
        fileInput.name.substring(fileInput.name.lastIndexOf('.') + 1) !== 'json'
      ) {
        return this.setMessageText(
          'Cannot import',
          'File must have a .json extension.'
        )
      }

      const fileData = await new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.onload = () => {
          resolve(JSON.parse(fr.result))
        }
        fr.onerror = reject
        fr.readAsText(file)
      })

      const matchesPlanId = fileData.planId !== this.rowData.planId
      const matchesClientId = fileData.clientId !== this.rowData.clientId

      if (matchesPlanId || matchesClientId) {
        return this.setMessageText(
          'Cannot import',
          `${matchesPlanId ? 'Plan ID ' : ''}` +
            `${matchesPlanId && matchesClientId ? 'and ' : ''}` +
            `${matchesClientId ? 'Client ID ' : ''}` +
            'does not match. Please check that the correct plan config is being imported.'
        )
      }

      this['plans/updateIsReview'](true)
      this.selectPlan(fileData)
    },
    handleModal (rowData, modalValue = true) {
      this.selectRow(rowData)
      this['plans/updateShowConfirmationModal'](modalValue)
    },
    displaySpinner (isLoading) {
      const tableSourceType = store.getters['plans/getTableSourceType']
      if (tableSourceType === 'JAVELINA') {
        this.exportLoading = isLoading
          ? this.rowData['clientId#planId']
          : isLoading
      } else {
        this.exportLoading = isLoading
          ? this.rowData['clientId#planId']
          : isLoading
      }
    },
    exportPlan (rowData) {
      const options = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }
      this.selectRow(rowData)
      this.displaySpinner(true)
      const tableSourceType = store.getters['plans/getTableSourceType']
      if (tableSourceType === 'JAVELINA') {
        planApi
          .getPlan(rowData.planId, rowData.clientId)
          .then((res) => {
            delete res.data.networkAffiliation
            delete res.data.networkAffilication
            const exportedPlanURL = URL.createObjectURL(
              new Blob([JSON.stringify(res.data)])
            )
            const completeRowData = document.createElement('a')
            completeRowData.href = exportedPlanURL
            completeRowData.setAttribute(
              'download',
              `${rowData.clientName}_${
                rowData.planId
              }_${new Date().toLocaleDateString('en-US', options)}.json`
            )
            document.body.appendChild(completeRowData)
            completeRowData.click()
            document.body.removeChild(completeRowData)
          })
          .then(() => {
            this.displaySpinner(false)
          })
          .catch((err) => {
            this.setMessageText(
              'Cannot export',
              'There was a problem exporting the file. Please try again later'
            )
            this.displaySpinner(false)
            console.log(err)
          })
      } else {
        planApi
          .getHealthEdgePlan(rowData.planId, rowData.clientId)
          .then((res) => {
            delete res.data.networkAffiliation
            const exportedPlanURL = URL.createObjectURL(
              new Blob([JSON.stringify(res.data)])
            )
            const completeRowData = document.createElement('a')
            completeRowData.href = exportedPlanURL
            completeRowData.setAttribute(
              'download',
              `${rowData.clientName}_${
                rowData.planId
              }_${new Date().toLocaleDateString('en-US', options)}.json`
            )
            document.body.appendChild(completeRowData)
            completeRowData.click()
            document.body.removeChild(completeRowData)
          })
          .then(() => {
            this.displaySpinner(false)
          })
          .catch((err) => {
            this.setMessageText(
              'Cannot export',
              'There was a problem exporting the file. Please try again later'
            )
            this.displaySpinner(false)
            console.log(err)
          })
      }
    },
    selectPlan (row, isNotificationEditor = false) {
      this.importLoading = false

      if (isNotificationEditor) {
        // console.log('before', row.notificationConfig.commsLibraryOptions.events.find(event => event.defaultEn === 'CP-2').isSelected)
        store.dispatch('plans/updatePlan', row).then(() => {
          this.$router.push({ path: '/plans/notification' })
        })
      } else {
        store.dispatch('plans/updatePlan', row)
        this.$router.push({ path: '/plans/setup' })
      }
    },
    getFormattedString (value) {
      return value && value !== '' ? value : '--'
    },
    getFormattedDate (date) {
      return date ? new Date(date).toLocaleDateString() : '--'
    },
    getFormattedEndDate (date) {
      const currentDate = moment()
      const endDate = moment(currentDate).add(12, 'months')
      let futureMonthEnd = moment(endDate).endOf('month')
      if (currentDate.date() !== futureMonthEnd.date() && futureMonthEnd.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
      futureMonthEnd = futureMonthEnd.add(1, 'd')
      } return '--'
    },
    getPlanStatus (plan) {
      if (plan.completed === true) {
        plan.status = PLAN_COMPLETED
      } else if (plan.started === true) {
        plan.status = PLAN_STARTED
      } else {
        plan.status = PLAN_NOT_STARTED
      }
    },
    getPlanStatusClass (planStatus) {
      switch (planStatus) {
        case PLAN_COMPLETED:
          return 'has-text-success'
        case PLAN_STARTED:
          return 'has-text-warning'
        default:
          return 'has-text-dark'
      }
    },
    getStoreDataWithStatus () {
      const storeData = store.getters['plans/getPlans']?.data ?? []
      storeData.forEach((plan) => {
        this.getPlanStatus(plan)
        plan['clientId#planId'] = `${plan.clientId}#${plan.planId}`
      })
      return storeData
    },
    searchPlans: _.debounce(function () {
      this.lastIndex = 20
      const lowerSearchCriteria = this.gridSearchCriteria.toLowerCase()
      if (lowerSearchCriteria) {
        this.gridData = this.getStoreDataWithStatus().filter(
          (plan) =>
            plan.clientName.toLowerCase().includes(lowerSearchCriteria) ||
            plan.clientId.toLowerCase().includes(lowerSearchCriteria) ||
            plan.planName.toLowerCase().includes(lowerSearchCriteria) ||
            plan.planId.toLowerCase().includes(lowerSearchCriteria)
          // ||
          // plan.status.toLowerCase().includes(lowerSearchCriteria)
        )
      } else {
        this.gridData = store.getters['plans/getPlans']?.data ?? []
      }
    }, 500),
    handleTab (index) {
      this.gridLoading = true
      this.gridData = []
      store.commit('plans/setPlans', [])
      if (index === 0) {
        store.commit('plans/setTableSourceType', 'JAVELINA')
        this.getAllPlans()
      } else {
        store.commit('plans/setTableSourceType', 'HRP')
        this.getAllHealthEdgePlans()
      }
    },
    getAllHealthEdgePlans () {
      store
        .dispatch('plans/getAllHealthEdgePlans')
        .then(() => {
          this.gridData = this.getStoreDataWithStatus()
          this.gridLoading = false
        })
        .catch((err) => console.error(err))
    },
    getAllPlans () {
      store
        .dispatch('plans/getAllConfig')
        .then((res) => {
          this.gridData = this.getStoreDataWithStatus()
          this.gridLoading = false
        })
        .catch((err) => {
          console.log(err)
        })

      // initial we wil fetch this.lastIndex number of plans and as people scoll wew will fetch more

      // TODO deprecate this once the pagination is implemneted
      // store
      //   .dispatch('plans/getPlans')
      //   .then(() => {
      //     this.gridData = this.getStoreDataWithStatus()
      //     // const unOrderedList = this.getStoreDataWithStatus()
      //     // this.gridData = unOrderedList.sort((a, b) => a.clientName.localeCompare(b.clientName))
      //     this.gridLoading = false
      //   })
      //   .catch((err) => {
      //     console.error(err)
      //   })
    },
    refreshHealthEdgeResults () {
      this.gridLoading = true

      store
        .dispatch('plans/syncHealthEdgePlans')
        .then(() => {
          store
            .dispatch('plans/getAllHealthEdgePlans')
            .then(() => {
              this.searchPlans()
              this.gridData = this.getStoreDataWithStatus()
              this.gridLoading = false
              this.$buefy.toast.open({
                message: 'HealthEdge list refreshed successfully!',
                type: 'is-success',
                duration: 8000,
                position: 'is-top'
              })
            })
            .catch(error => {
              console.error(error)
            })
        })
        .catch(error => {
          console.error(error)
          this.gridLoading = false
          this.$buefy.toast.open({
            message: 'It was not possible to refresh the HealthEdge list',
            type: 'is-danger',
            duration: 8000,
            position: 'is-top'
          })
        })
    },
    refreshResults () {
      this.gridLoading = true

      store
        .dispatch('plans/syncPlans')
        .then((res) => {
          store
            .dispatch('plans/getAllConfig')
            .then(() => {
              this.searchPlans()
              this.gridData = this.getStoreDataWithStatus()
              this.gridLoading = false
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.error(err)
          this.gridLoading = false
          this.$buefy.toast.open({
            message: 'It was not possible to refresh the javelina list',
            type: 'is-danger',
            duration: 8000,
            position: 'is-top'
          })
        })
    },
    logClientCoverage (clientId) {
      this.selectedClient = this.getClients.Items.find(
        (client) =>
          client['productType#planId#configType'].split('#')[1] === clientId
      )
    }
  }
}
</script>
<style lang="scss">
.is-centered-vertically {
  display: flex;
  align-items: center;
}
.is-centered-field {
  display: flex;
  align-items: center;
}
.grid-search {
  padding-top: 30px;
  padding-bottom: 20px;
}
.grid-outer {
  background-color: rgb(219, 219, 219);
  padding: 1.25px;
}
.taller-cell {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  font-family: "Open Sans", sans-serif;
}
.b-table .table {
  border-width: 0 !important;
}
.left-align {
  text-align: left !important;
}
.no-bottom-border {
  border-bottom: none !important;
}
.no-left-border {
  border-left: none !important;
}
.no-right-border {
  border-right: none !important;
}
.no-border {
  border: none !important;
}
.no-padding {
  padding-top: 0px;
}
.modal-content#confirmationModal {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.15);
  width: 340px !important;
}
.modal-title#confirmationModalTitle {
  font-family: "Centivo-Bold";
}
.modal-text#confirmationModalText {
  font-family: "Centivo-Medium";
  margin-bottom: 10px;
}
.confirmButton {
  background: #ec008c;
  border-radius: 400px;
  height: 50px;
  width: 295px;
  color: #ffffff;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  outline: #ec008c 1px auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectBtn {
  color: #fac640;
}
.editBtn {
  color: #ec008c;
}
.b-table .table tr.detail {
  background-color: #ffecf0;

  td {
    border: 1px solid red;
    border-radius: 5px;
    text-align: left;
    padding: 0;

    div.detail-container {
      padding: 0;
    }
  }
}

.has-text-info {
  position: relative;
}
.has-text-info::before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 50px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}
.has-text-info:hover::before {
  opacity: 1;
  visibility: visible;
}

.has-text-success {
  position: relative;
}
.has-text-success::before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 40px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}
.has-text-success:hover::before {
  opacity: 1;
  visibility: visible;
}

.icons-position {
  width: 149px;

  .column-icons {
    padding: 19.5px 32px 0px 0px;
  }
  .icon-left {
    padding-left: 8px;
    padding-top: 18.5px;
  }
  .icon-right {
    padding-right: 8px;
  }
}

.disabled-opacity {
  opacity: 0.4;
  cursor: default;
}

div.plans-grid {
  .table td,
  .table th {
    vertical-align: middle;
  }
}
</style>
